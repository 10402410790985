import React from "react";
import { Link } from "gatsby";
import BlockContent from "@sanity/block-content-to-react";
import SEO from "../components/seo";

/* How It Works Page
============================================================================= */

const HowItWorksPage = ({ data }) => {
	// Config
	const { sanityIsolaid } = data;
	const {
		_rawHiwmobile: hiwmobile,
		_rawHiwdesktop: hiwdesktop,
	} = sanityIsolaid;

	// Render
	return (
		<>
			<SEO title="How It Works" />
			<div className="w-full flex lg:flex-row flex-col">
				<div className="lg:w-1/2 w-full pl-10 lg:pr-72 pr-12 pt-24 pb-4 flex flex-col">
					<h2 className="text-darkblue text-5xl font-sans font-black pb-6 ibm-plex">
						HOW IT WORKS
					</h2>
					<h4 className="text-darkblue text-3xl font-sans font-black pb-6 ibm-plex">
						ON MOBILE
					</h4>
					<p className="text-darkblue text-base font-sans pb-6 ibm-plex block-content">
						<BlockContent blocks={hiwmobile} />
					</p>
				</div>
				<div className="lg:w-1/2 w-full">
					<div className="w-full h-220 bg-orange" />
				</div>
			</div>
			<div className="w-full flex lg:flex-row flex-col-reverse">
				<div className="lg:w-1/2 w-full">
					<div className="w-full h-220 bg-darkblue" />
				</div>
				<div className="lg:w-1/2 w-full pl-10 lg:pr-72 pr-12 pt-24 pb-4 flex flex-col">
					<h4 className="text-darkblue text-3xl font-sans font-black pb-6 ibm-plex">
						ON DESKTOP
					</h4>
					<p className="text-darkblue text-base font-sans pb-6 ibm-plex block-content">
						<BlockContent blocks={hiwdesktop} />
					</p>
				</div>
			</div>
		</>
	);
};

/* Page Query
============================================================================= */

export const pageQuery = graphql`
	query HowItWorksPageQuery {
		sanityIsolaid {
			_rawHiwmobile(resolveReferences: { maxDepth: 10 })
			_rawHiwdesktop(resolveReferences: { maxDepth: 10 })
		}
	}
`;

/* Export
============================================================================= */

export default HowItWorksPage;
